$primary-color: #0a484f;
$secondary-color: #e04c38;
$error-color: #e04c38;
$classic-color: #ffffff;
$disabled-color: #e2e2e2;
$background-color: #f2f2f2;
$text-color: #0a484f;
$grey-color: #707070;
$success-color: #2fbd2f;

$border-radius: 8;

$breaking-point: 990px;
