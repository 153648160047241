@import '_variables';

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: none;
  background-color: #f2f2f2;
}

.inputlogin::placeholder {
  color:#FFFFFF;
}

#root {
  height: 100%;
}

iframe {
  width: 100%;
  height: 94vh;
  border: 0;
  border-radius: 10px;
}

.swiper-button-prev,
.swiper-button-next {
  color: #0a484f !important; //FIXME : Should get primary color from config.js #17213c
  width: 50px;
  height: 50px;
  background-color: white;
  // border: 1px solid grey;
  opacity: 0.7;
  padding: 8px 16px;
  border-radius: 50%;
  // border: 1px solid white;
}

.swiper-pagination.swiper-pagination-bullets {
  & > span {
    background-color: #e04c38 !important; //FIXME : Should get primary color from config.js
  }
}

.menudiv {
  @media (min-width: $breaking-point + 1px) {
    display: none;
  }
  @media (max-width: $breaking-point) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    .desktopMenu {
      display: none;
    }
    .menuIcon {
      text-decoration: none;
      position: fixed;
      top: 8px;
      right: 8px;
      font-size: 32px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      text-align: center;
      z-index: 10;
    }
  }
}

.desktopMenu {
  @media (min-width: $breaking-point + 1px) {
    position: absolute;
    padding: 16px;
    height: 100%;
    left: -40%;
    top: 0;
    border-right: 1px solid $disabled-color;
    background-color: $background-color;
  }
  @media (max-width: $breaking-point) {
    display: none;
  }
}

.bottomMenuMobile {
  @media (min-width: $breaking-point + 1px) {
    display: none;
  }
  @media (max-width: $breaking-point) {
    display: flex;
  }
}

.menuDesktopAcceuil {
  @media (min-width: $breaking-point + 1px) {
    display: flex;
    justify-content: space-around;
    margin-top: 16px;
  }
  @media (max-width: $breaking-point) {
    display: none;
  }
}

.App {
  max-width: 610px;
  margin: auto;
  @media (min-width: $breaking-point + 1px) and (max-width: 1100px) {
    margin-right: 64px;
  }
}
